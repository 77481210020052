import {Component} from '@angular/core';

import {
  HsConfig,
  HsEventBusService,
  HsLanguageService,
  HsLayoutService,
  HsPanelContainerService,
  HsSidebarService,
  HsToastService,
} from 'hslayers-ng';

import {AppService} from './app.service';
import {CalculatorComponent} from './calculator/calculator.component';

@Component({
  selector: 'application-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /* You can name your app as you like or not at all */
  title = 'hslayers-application';
  constructor(
    private appService: AppService,
    /* Inject here all modules from HSLayers-NG which you intend to use */
    public hsConfig: HsConfig,
    private hsEventBus: HsEventBusService,
    private hsLanguageService: HsLanguageService,
    private hsLayoutService: HsLayoutService,
    public hsPanelContainerService: HsPanelContainerService,
    public hsSidebarService: HsSidebarService,
    private hsToastService: HsToastService
  ) {
    /* Create new button in the sidebar */
    this.hsSidebarService.addButton({
      panel: 'calculator',
      module: 'calculator',
      order: 0,
      title: 'Field Calculator', //() =>
      //this.hsLanguageService.getTranslation('ADJUSTER.adjustFactors'),
      description: 'Adjust factors for computation',
      icon: 'icon-analytics-piechart',
    });
    /* Create new panel itself */
    this.hsPanelContainerService.create(CalculatorComponent, {});
    /* Switch language to cs */
    this.hsEventBus.layoutLoads.subscribe(() => {
      this.hsLanguageService.setLanguage('en');
      this.hsLayoutService.setDefaultPanel('calculator');
    });
  }
}
