<div [hidden]="!isVisible()" class="card hs-main-panel">
  <hs-panel-header name="adjuster"
    [title]="hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'panelHeader')">
    <extra-buttons>
      <!-- LOADER -->
      <div class="spinner-border spinner spinner-sm mx-2" role="status"
        [title]="hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'loading')"
        *ngIf="calcService.lpisLoading">
        <span class="visually-hidden">{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
          'loading')}}...</span>
      </div>
    </extra-buttons>
  </hs-panel-header>
  <div class="card-body">
    <div class="p-4 m-auto">
      <!-- FIELD & INDEX SELECTION PART -->
      <div *ngIf="!noFieldSelected(); else noField">
        <p *ngIf="data.selectedFieldsProperties.length === 1; else moreFields">
          {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'selectedField')}}
          {{data.selectedFieldsProperties[0]?.['id_dpb'] ?? '?'}}
        </p>
        <ng-template #moreFields>
          <p>
            {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'selectedFields')}}
            <span *ngFor="let props of data.selectedFieldsProperties; last as isLast">
              {{props?.['id_dpb'] ?? '?'}}<ng-container *ngIf="!isLast">,</ng-container>
            </span>
          </p>
        </ng-template>
      </div>
      <ng-template #noField>
        <div>
          <p class="p-1 text-info">{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'selectField')}}
          </p>
        </div>
      </ng-template>
      <p class="p-1 text-warning" *ngIf="!lpisWfsVisible"><i
          class="icon-warning-sign"></i>&nbsp;{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
        'zoomIn')}}</p>
      <div class="form-group">
        {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'selectIndex')}}:&emsp;
        <select class="form-select" [(ngModel)]="data.selectedProduct" (ngModelChange)="resetDate()">
          <option selected disabled [ngValue]="null">{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
            'selectIndexHint')}}</option>
          <option *ngFor="let product of calcService.AVAILABLE_PRODUCTS" [ngValue]="product">{{product}}</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
          'selectQuantiles')}}:&emsp;{{calcService.quantileCount}}</label>
        <input type="range" min="2" max="10" step="1" [(ngModel)]="calcService.quantileCount" class="form-range">
      </div>
      <div class="form-group">
        <label>
          {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
          'selectBlur')}}:&emsp;{{calcService.blurValue}}&nbsp;px <span
            *ngIf="calcService.blurValue === 0">({{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
            'blurNone')}})</span>
        </label>
        <input type="range" min="{{calcService.BLUR_MIN_VALUE}}" max="{{calcService.BLUR_MAX_VALUE}}" step="1"
          [(ngModel)]="calcService.blurValue" class="form-range">
      </div>
      <div class="form-group d-flex m-auto">
        <button type="button" class="btn btn-secondary form-control" (click)="getDates()"
          [disabled]="noFieldSelected() || noProductSelected()">
          {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'getDates')}}
          <div class="spinner-border spinner-sm mx-2" role="status" *ngIf="calcService.datesLoading" aria-hidden="true">
            <span class="visually-hidden">{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
              'loading')}}...</span>
          </div>
        </button>
        <!-- LOADER -->
      </div>
      <!-- DATE SELECTION PART -->
      <div [hidden]="noDates()">
        <ngb-datepicker #dp [(ngModel)]="calcService.selectedDateCalendar" (ngModelChange)="updateSelectedDate($event)"
          [dayTemplate]="customDay" class="form-control"></ngb-datepicker>
        <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected"
          let-disabled="disabled" let-focused="focused">
          <span class="custom-day lol" [class.focused]="focused" [class.bg-primary]="selected"
            [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled"
            [class.has-task]="hasDataAvailable(date)">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div class="d-flex" *ngIf="!noDates()">
        <button type="button" class="btn btn-primary form-control" (click)="getZones()" [disabled]="noDateSelected()">
          {{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR', 'getZones')}}
          <!-- LOADER -->
          <div class="spinner-border spinner-sm mx-2" role="status" *ngIf="calcService.zonesLoading" aria-hidden="true">
            <span class="visually-hidden">{{hsLanguageService.getTranslationIgnoreNonExisting('CALCULATOR',
              'loading')}}...</span>
          </div>
        </button>
      </div>
      <span class="error-message">{{calcService.lastError}}</span>
    </div>
  </div>
</div>
