import {BehaviorSubject} from 'rxjs';
import {Component, OnInit, ViewRef} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {
  HsLanguageService,
  HsLayoutService,
  // eslint-disable-next-line import/named
  HsPanelComponent,
} from 'hslayers-ng';

import {CalculatorService, Index} from './calculator.service';
import {FieldService} from './field.service';

@Component({
  selector: 'calculator-panel',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements HsPanelComponent, OnInit {
  data: {
    selectedProduct: Index;
    selectedFieldsProperties: {[x: string]: any}[];
  };
  lpisWfsVisible: boolean;
  name: 'calculator';
  viewRef: ViewRef;
  isVisible$ = new BehaviorSubject<boolean>(true);

  constructor(
    public calcService: CalculatorService,
    private fieldService: FieldService,
    public hsLanguageService: HsLanguageService,
    public hsLayoutService: HsLayoutService
  ) {
    this.fieldService.fieldSelects.subscribe(({features}) => {
      this.data.selectedFieldsProperties = [];
      for (const feature of features) {
        this.data.selectedFieldsProperties.push(feature.getProperties());
      }
    });
    this.calcService.viewChanges.subscribe((view) => {
      if (view.getZoom() > this.calcService.MIN_LPIS_VISIBLE_ZOOM) {
        this.lpisWfsVisible = true;
        return;
      }
      this.lpisWfsVisible = false;
    });
  }
  ngOnInit() {
    this.data.selectedProduct = null;
  }

  isVisible(): boolean {
    return this.hsLayoutService.panelVisible('calculator');
  }

  noFieldSelected(): boolean {
    return this.fieldService.noFieldSelected();
  }

  noProductSelected(): boolean {
    return this.data.selectedProduct === null;
  }

  noDates(): boolean {
    return this.calcService.noDates();
  }

  noDateSelected(): boolean {
    return this.calcService.selectedDate === undefined;
  }

  getDates() {
    this.calcService.getDates({product: this.data.selectedProduct});
  }

  getZones() {
    this.calcService.getZones({
      product: this.data.selectedProduct,
    });
  }

  /**
   * Used when product is changed, so the selected date can be reset
   */
  resetDate() {
    this.calcService.selectedDate = undefined;
    this.calcService.selectedDateCalendar = undefined;
    this.calcService.availableDates = undefined;
  }

  updateSelectedDate(value: NgbDateStruct) {
    this.calcService.selectedDate = `${value.year}-${
      value.month < 10 ? '0' : ''
    }${value.month}-${value.day < 10 ? '0' : ''}${value.day}`;
    this.calcService.updateImageBackground(this.calcService.selectedDate);
  }

  hasDataAvailable(date: NgbDateStruct): boolean {
    if (this.calcService && this.calcService.availableDates) {
      const found = this.calcService.availableDates.filter((item, index) => {
        return (
          item.indexOf(
            `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day
              .toString()
              .padStart(2, '0')}`
          ) == 0
        );
      }); // date.year == 2022 && date.month == 6 && date.day == 16;
      return found.length > 0;
    }

    return false;
  }
}
