import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {Feature} from 'ol';
import {Geometry, Polygon} from 'ol/geom';
import {getCenter} from 'ol/extent';
import {transform} from 'ol/proj';

import {HsEventBusService, HsMapService} from 'hslayers-ng';

@Injectable({providedIn: 'root'})
export class FieldService {
  SELECTABLE_LAYERS = ['LPIS (WFS)'] as const;
  fieldSelects: Subject<{features: Feature<Geometry>[]}> = new Subject();
  selectedFields;

  constructor(
    private hsEventBus: HsEventBusService,
    private hsMapService: HsMapService
  ) {
    this.hsEventBus.vectorQueryFeatureSelection.subscribe((data) => {
      const features = data.selector.getFeatures().getArray();
      //  .filter((feature) => this.isValidGeometry(feature))
      //  .filter((feature) => this.isValidLayer(feature));
      if (features.length === 0) {
        return;
      }
      this.selectedFields = features.map(
        (feature) => feature.getGeometry() as Polygon
      );
      this.fieldSelects.next({features: features});
    });
  }

  noFieldSelected(): boolean {
    return this.selectedFields === undefined;
  }

  getCentroidOfField(field: Geometry) {
    return {
      type: 'Point',
      coordinates: transform(
        getCenter(field.getExtent()),
        'EPSG:5514',
        'EPSG:4326'
      ),
    };
  }

  getSelectedFieldCentroid() {
    return this.getCentroidOfField(this.selectedFields[0]);
  }

  getSelectedFieldGeoJSON() {
    return {
      type: 'FeatureCollection',
      name: 'Selected field',
      features: this.selectedFields.map((feature) =>
        this.getGeoJSONFromFeature(feature)
      ),
    };
  }

  /**
   * Check whether selected feature geometry type is valid
   * (API expects polygon only)
   */
  isValidGeometry(feature) {
    if (!feature) {
      return false;
    }
    return feature.getGeometry().getType() == 'Polygon';
  }

  /**
   * Check whether user clicked into one of selectable layers
   */
  isValidLayer(feature) {
    const layer = this.hsMapService.getLayerForFeature(feature, null);
    return this.SELECTABLE_LAYERS.includes(layer.get('title'));
  }

  private getGeoJSONFromFeature(feature) {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: feature
          .clone()
          .transform('EPSG:5514', 'EPSG:4326')
          .getCoordinates(),
      },
    };
  }
}
