import {Injectable} from '@angular/core';

import proj4 from 'proj4';
import {GeoJSON} from 'ol/format';
import {OSM, TileWMS, Vector as VectorSource} from 'ol/source';
import {Tile, Vector as VectorLayer} from 'ol/layer';
import {View} from 'ol';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import {get as getProjection, transform} from 'ol/proj';
import {register} from 'ol/proj/proj4';

import {
  HsConfig,
  HsEventBusService,
  HsLanguageService,
  HsLayerManagerService,
  HsLayoutService,
  HsPanelContainerService,
  HsSidebarService,
} from 'hslayers-ng';

import i18n from './translations.json';
import {CalculatorService} from './calculator/calculator.service';
import {imageWmsTLayer} from './calculator/image-wms-t-layer';

proj4.defs(
  'EPSG:3045',
  '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
proj4.defs(
  'EPSG:5514',
  '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=542.5,89.2,456.9,5.517,2.275,5.516,6.96 +units=m +no_defs'
);
register(proj4);

@Injectable({providedIn: 'root'})
export class AppService {
  sjtskProjection = getProjection('EPSG:5514');
  constructor(
    public calcService: CalculatorService,
    public hsConfig: HsConfig,
    public hsEventBus: HsEventBusService,
    public hsLanguageService: HsLanguageService,
    public hsLayerManagerService: HsLayerManagerService,
    public hsLayoutService: HsLayoutService,
    public hsPanelContainerService: HsPanelContainerService,
    public hsSidebarService: HsSidebarService
  ) {
    /* Define the polygon's style using SLD */
    const fieldSld = `<?xml version="1.0" encoding="ISO-8859-1"?>
      <StyledLayerDescriptor version="1.0.0" 
          xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd" 
          xmlns="http://www.opengis.net/sld" 
          xmlns:ogc="http://www.opengis.net/ogc" 
          xmlns:xlink="http://www.w3.org/1999/xlink" 
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <NamedLayer>
          <Name>Simple point with stroke</Name>
          <UserStyle>
            <Title>Default</Title>
            <FeatureTypeStyle>
              <Rule>
              <PolygonSymbolizer>
              <Fill>
                <CssParameter name="fill">#ffffff</CssParameter>
                <CssParameter name="fill-opacity">0.7</CssParameter>
              </Fill>
              <Stroke>
                <CssParameter name="stroke">#33cccc</CssParameter>
                <CssParameter name="stroke-opacity">1</CssParameter>
                <CssParameter name="stroke-width">2.0</CssParameter>
              </Stroke>
              </PolygonSymbolizer>
              </Rule>
            </FeatureTypeStyle>
          </UserStyle>
        </NamedLayer>
      </StyledLayerDescriptor>
    `;
    const lpisSource = new VectorSource({
      format: new GeoJSON({
        dataProjection: 'EPSG:5514',
      }),
      url: (extent) => {
        const kulturaKod = 1; // Doesn't seem to work
        const proxyPath = window.location.hostname.includes('localhost')
          ? 'http://localhost:8085/'
          : '/proxy/';
        return (
          proxyPath +
          'https://gis.lesprojekt.cz/cgi-bin/mapserv?map=/home/dima/maps/foodie/lpis.map' +
          '&service=WFS' +
          '&VERSION=1.1.0' +
          '&REQUEST=GetFeature' +
          '&TYPENAME=lpis_borders' +
          '&COUNT=100' +
          '&outputformat=geojson' +
          '&SRSNAME=EPSG:5514' +
          `&BBOX=${extent ? extent.join(',') : ''}`
          // + `FILTER=&<ogc:Filter xmlns:ogc="http://www.opengis.net/ogc"><ogc:PropertyIsEqualTo><ogc:PropertyName>kultura</ogc:PropertyName><ogc:Literal>${kulturaKod}</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter>`
        );
        //%3Cgml:Box%3E%3Cgml:coordinates%3E${
        //extent.join(',')
        //}%3C/gml:coordinates%3E%3C/gml:Box%3E%3C/ogc:Filter%3E`;
      },
      strategy: bboxStrategy,
    });
    lpisSource.on(
      'featuresloadstart',
      () => (this.calcService.lpisLoading = true)
    );
    lpisSource.on(
      'featuresloadend',
      () => (this.calcService.lpisLoading = false)
    );
    lpisSource.on('featuresloaderror', (evt) => {
      this.calcService.lpisLoading = false;
      console.warn('error when loading LPIS layer');
      console.log(evt);
    });
    /* Define and update the HsConfig configuration object */
    this.hsConfig.update(
      {
        datasources: [
          /* You need to set up Layman in order to use it. See https://github.com/LayerManager/layman */
          /*{
            title: 'Layman',
            url: 'http://localhost:8087',
            user: 'anonymous',
            type: 'layman',
            liferayProtocol: 'https',
          },*/
          {
            title: 'Micka',
            url: 'https://www.agrihub.cz/micka/csw',
            language: 'eng',
            type: 'micka',
          },
        ],
        default_view: new View({
          projection: this.sjtskProjection,
          center: transform([16.964, 49.248], 'EPSG:4326', 'EPSG:5514'),
          zoom: 14,
        }),
        /* Use hslayers-server if you need to proxify your requests to other services. See https://www.npmjs.com/package/hslayers-server */
        proxyPrefix: window.location.hostname.includes('localhost')
          ? `${window.location.protocol}//${window.location.hostname}:8085/`
          : '/proxy/',
        useProxy: true,
        panelsEnabled: {
          composition_browser: true,
          info: false,
          saveMap: false,
          legend: false,
          tripPlanner: false,
        },
        componentsEnabled: {
          basemapGallery: true,
        },
        assetsPath: 'assets',
        symbolizerIcons: [
          {name: 'beach', url: '/assets/icons/beach17.svg'},
          {name: 'bicycles', url: '/assets/icons/bicycles.svg'},
          {name: 'coffee-shop', url: '/assets/icons/coffee-shop1.svg'},
          {name: 'mountain', url: '/assets/icons/mountain42.svg'},
          {name: 'warning', url: '/assets/icons/warning.svg'},
        ],
        popUpDisplay: 'hover',
        default_layers: [
          /* Baselayers */
          new Tile({
            source: new OSM(),
            visible: true,
            properties: {
              title: 'OpenStreetMap',
              base: true,
              removable: false,
            },
          }),
          new Tile({
            properties: {
              title: 'Ortofoto ČÚZK',
              base: true,
              removable: false,
              thumbnail: 'https://www.agrihub.sk/hsl-ng/img/orto.jpg',
            },
            source: new TileWMS({
              url: 'https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx',
              params: {
                LAYERS: 'GR_ORTFOTORGB',
              },
              attributions: [
                '© <a href="geoportal.cuzk.cz" target="_blank">ČÚZK</a>',
              ],
            }),
            visible: false,
          }),
          /* Thematic layers */
          imageWmsTLayer,
          new VectorLayer({
            properties: {
              title: 'LPIS (WFS)',
              synchronize: false,
              cluster: false,
              inlineLegend: true,
              editor: {
                editable: false,
              },
              sld: fieldSld,
              popUp: {
                attributes: [
                  'id_dpb',
                  'id_uz',
                  'nkod_dpb',
                  'kultura',
                  'svazitost',
                  'vymeram',
                ],
              },
              //path: 'User generated',
            },
            minZoom: this.calcService.MIN_LPIS_VISIBLE_ZOOM,
            opacity: 0.7,
            source: lpisSource,
          }),
          new Tile({
            properties: {
              title: 'LPIS (WMS)',
              queryCapabilities: false,
            },
            maxZoom: this.calcService.MIN_LPIS_VISIBLE_ZOOM,
            source: new TileWMS({
              url: 'https://gis.lesprojekt.cz/cgi-bin/mapserv?map=/home/dima/maps/foodie/lpis.map',
              params: {
                LAYERS: 'lpis_borders', //'lpis_cultures'
                INFO_FORMAT: undefined,
                FORMAT: 'image/png; mode=8bit',
              },
              crossOrigin: 'anonymous',
            }),
          }),
        ],
        translationOverrides: i18n,
      },
      'default'
    );
  }
}
