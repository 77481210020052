import {Tile} from 'ol/layer';
import {TileWMS} from 'ol/source';

export const imageWmsTSource = new TileWMS({
  url: 'http://gis.lesprojekt.cz/cgi-bin/mapserv?map=/home/dima/maps/veg_indexy.map&SERVICE=WMS',
  params: {
    LAYERS: 'tci',
    time: '20200111',
  },
});

export const imageWmsTLayer = new Tile({
  properties: {
    title: 'Vegetation Satellite Image',
    base: false,
    removable: false,
    dimensions: {time: {onlyInEditor: true}},
  },
  source: imageWmsTSource,
  visible: false,
});
